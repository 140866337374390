import React, {lazy} from "react";

export const showList = [
    {
        year: 2022,
        title: "Microsoft Ads",
        type: ['design', 'video'],
        url: "microsoft",
        file: lazy(()=>import("../layout/Page/Work/Pages/microsoft/microsoft"))
    },
    {
        year: 2022,
        title: "Teal HQ",
        type: ['design'],
    },
    {
        year: 2022,
        title: "Qargo",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Anais and Co",
        type: ['design', 'code', 'video'],
        url: "anais",
        file: lazy(()=>import("../layout/Page/Work/Pages/anais/anais"))
    },
    {
        year: 2021,
        title: "Array",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Wearisma",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Urbyn",
        type: ['design'],
    },
    {
        year: 2021,
        title: "MyDesq",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Loanjoy",
        type: ['design'],
    },
    {
        year: 2021,
        title: "Simple French",
        type: ['design'],
    },
    {
        year: 2020,
        title: "HSBC",
        type: ['design', 'video'],
        url: "hsbc",
        file: lazy(()=>import("../layout/Page/Work/Pages/hsbc/hsbc"))
    },
    {
        year: 2020,
        title: "Symphony",
        type: ['design'],
    },
    {
        year: 2020,
        title: "Agency Analytics",
        type: ['design'],
    },
    {
        year: 2020,
        title: "FIA",
        type: ['design'],
    },
    {
        year: 2020,
        title: "Twiner",
        type: ['design'],
    },
    {
        year: 2020,
        title: "Henig diamonds",
        type: ['design'],
    },
    {
        year: 2020,
        title: "Goat",
        type: ['design'],
    },
    {
        year: 2020,
        title: "Feedly",
        type: ['design', 'video'],
        url: "feedly",
        file: lazy(()=>import("../layout/Page/Work/Pages/feedly/feedly"))
    },
    {
        year: 2019,
        title: "Bentley",
        type: ['design', 'video'],
        url: "bentley",
        file: lazy(()=>import("../layout/Page/Work/Pages/Bentley/Bentley"))
    },
    {
        year: 2019,
        title: "Dribbble",
        type: ['design', 'video'],
        url: "dribbble",
        file: lazy(()=>import("../layout/Page/Work/Pages/Dribbble/Dribbble"))
    },
    {
        year: 2019,
        title: "Avora",
        type: ['design'],
    },
    {
        year: 2019,
        title: "Frontegg",
        type: ['design'],
    },
    {
        year: 2019,
        title: "Harrods",
        type: ['design'],
    },
    {
        year: 2019,
        title: "Moveassist",
        type: ['design', 'code'],
    },
    {
        year: 2019,
        title: "Shuttlerock",
        type: ['design', 'code'],
    },
    {
        year: 2019,
        title: "High Acuity",
        type: ['design', 'code'],
    },
    {
        year: 2019,
        title: "Travers Smith",
        type: ['design'],
    },
    {
        year: 2019,
        title: "Joblab",
        type: ['design'],
    },
    {
        year: 2019,
        title: "Corti",
        type: ['design'],
    },
    {
        year: 2019,
        title: "Compare Stack",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Amazon",
        type: ['design', 'video'],
        url: "amazon",
        file: lazy(()=>import("../layout/Page/Work/Pages/amazon/amazon"))
    },
    {
        year: 2018,
        title: "PDF Shift",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Aurae lifestyle",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Clubswan",
        type: ['design'],
    },
    {
        year: 2018,
        title: "My PT Hub",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Closana",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Martin Bruusgaard",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Yogi Network",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Linklaters",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Complimento",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Fitii",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Freshfields",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Socialix",
        type: ['design'],
    },
    {
        year: 2018,
        title: "Empala",
        type: ['design'],
    },
    {
        year: 2018,
        title: "AI Media",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Trading network",
        type: ['design', 'video'],
        url: "trading",
        file: lazy(()=>import("../layout/Page/Work/Pages/trading/trading"))
    },
    {
        year: 2017,
        title: "OTA Insight",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Picked AI",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Movivo",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Test candidates",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Butterfly app",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Botpress",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Mendeley",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Osome",
        type: ['design', 'video'],
        url: "osome",
        file: lazy(()=>import("../layout/Page/Work/Pages/osome/osome"))
    },
    {
        year: 2017,
        title: "Boatim",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Target Test Prep",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Fuuto",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Metanoya",
        type: ['design'],
    },
    {
        year: 2017,
        title: "Girlectics",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Linkedin",
        type: ['design', 'video'],
        url: "linkedin",
        file: lazy(()=>import("../layout/Page/Work/Pages/linkedin/linkedin"))
    },
    {
        year: 2016,
        title: "Proprio Direct",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Talklife",
        type: ['design'],
    },
    {
        year: 2016,
        title: "M Pulso",
        type: ['design'],
    },
    {
        year: 2016,
        title: "IFAD",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Animys",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Wearisma",
        type: ['design'],
    },
    {
        year: 2016,
        title: "KS Quadrat",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Crosscan",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Good to Go",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Hella",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Insight 360",
        type: ['design'],
    },
    {
        year: 2016,
        title: "My PT Hub",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Ladenburg",
        type: ['design', 'code'],
    },
    {
        year: 2016,
        title: "Cartier",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Crepe Affaire",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Den",
        type: ['design'],
    },
    {
        year: 2016,
        title: "Multiven",
        type: ['design', 'code'],
    },
    {
        year: 2016,
        title: "My PT Hub",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Retail App",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Fitterfaster",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Shippabo",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Retail App",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Zooqit",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Pingsta",
        type: ['design', 'code'],
    },
    {
        year: 2015,
        title: "Visible Classroom",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Wordcoach",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Book a Tiger",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Microhealth",
        type: ['design', 'code'],
    },
    {
        year: 2015,
        title: "Jointfully",
        type: ['design', 'code'],
    },
    {
        year: 2015,
        title: "Dr Doctor",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Canvs",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Harrods",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Widespread",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Closir",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Signal",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Truedash",
        type: ['design'],
    },
    {
        year: 2015,
        title: "Paatle",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Moms Affiliate",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Snapscan",
        type: ['design'],
    },
    {
        year: 2014,
        title: "EIFL",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Bluecava",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Paktor",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Thred",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Emmerge",
        type: ['design'],
    },
    {
        year: 2014,
        title: "Yawave",
        type: ['design'],
    },
    {
        year: 2013,
        title: "Triggertrap",
        type: ['design'],
    },
    {
        year: 2013,
        title: "Bodytrack",
        type: ['design'],
    },
    {
        year: 2013,
        title: "Mixdata",
        type: ['design'],
    },
    {
        year: 2013,
        title: "Checkout",
        type: ['design'],
    },
    {
        year: 2013,
        title: "Betshare",
        type: ['design'],
    },
    {
        year: 2013,
        title: "Sidebar",
        type: ['design'],
    },
    {
        year: 2012,
        title: "Traveljuice",
        type: ['design'],
    },
    {
        year: 2012,
        title: "Advansolar",
        type: ['design'],
    },
    {
        year: 2012,
        title: "Quipio",
        type: ['design'],
    },
    {
        year: 2012,
        title: "My Best Fan",
        type: ['design'],
    },
]
