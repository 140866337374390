import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";

import Home from "./Page/Home/Home";
import Header from "./Header/Header";
import Menu from "./Menu/Menu";
import ShowFooter from "./ShowFooter/ShowFooter";
import Work from "./Page/Work/Work";
import LoadingBar from "../components/LoadingBar/LoadingBar";



const MainLayout = props => {

    const navigate = useNavigate();


    const [showOpen, setShowOpen] = useState(false);
    const [tellOpen, setTellOpen] = useState(false);
    const [workOpen, setWorkOpen] = useState(false);
    const [showLoadingBar, setShowLoadingBar] = useState(false)

    //Used by header and menu to set backgrounds and positions while moving
    const [moving, setMoving] = useState(false);

    //Changing from show to tell while open
    const [changing, setChanging] = useState(false);

    //Closing menu
    const [fade, setFade] = useState(false);

    //Closing work menu
    const [closingWork, setClosingWork] = useState(false)

    //Work opening
    const [workOpening, setWorkOpening] = useState(false)

    //Fix menu
    const [fixMenu, setFixMenu] = useState(false)





    const openMenu = menu => {

        // If menu moving block any actions
        if(moving) return


        //If menu not open yet
        if(!showOpen && !tellOpen){
            slideMenu()
        } else {

            //console.log(menu === "tell", tellOpen)

            if((menu === "show" && showOpen) || (menu === "tell" && tellOpen)) {
                changeMenu(true)
            } else {
                changeMenu(false)
            }


        }


        if(menu === "tell" ){
            setTellOpen(true)
            setShowOpen(false)
        }

        if(menu === 'show'){
            setShowOpen(true)
            setTellOpen(false)
        }



    }

    //Fade out menu
    const closeMenu = () => {

        //Block action if menu open
        if(!showOpen && !tellOpen) return

        setMoving(true)
        setFade(true)


        //remove active marks and reset URL
        setTimeout(()=> {
            setTellOpen(false)
            setShowOpen(false)

            navigate('/')

        },1000)

        //Reset menu position
        setTimeout(()=> {
            setMoving(false)
            setFade(false)
        }, 2000)

    }

    //Slide menu up
    const slideMenu = () => {
        setShowLoadingBar(true);
        setMoving(true)
        setTimeout(()=>setMoving(false), 1000);
    }

    // Switching between menus while open
    const changeMenu = withScroll => {
        setShowLoadingBar(true);
        setChanging(true)
        setTimeout(()=>{

            //Scroll back to top
            if(withScroll){
                window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }
            props.dispatch('WINDOW_SCROLL', 0);

            setChanging(false)

        }, 750);
    }


    //Open work popup
    const openWork = () => {
        //console.log("open")
        setShowLoadingBar(true);
        setWorkOpening(true)
        setWorkOpen(true)

        setTimeout(()=> {
            window.scrollTo(0, 0);
            props.dispatch('WINDOW_SCROLL', 0);
        }, 500);

        setTimeout(()=>{
            setWorkOpening(false)
            setFixMenu(true)
        },1000)
    }


    //Close work popup
    const closeWork = () => {

        setClosingWork(true)
        window.scrollTo(0, 0);
        props.dispatch('WINDOW_SCROLL', 0);

        //Reset scroll
        setTimeout(()=>{
            // window.scrollTo(0, 0);
            // props.dispatch('WINDOW_SCROLL', 0);
        }, 500)

        //Reset URL and states
        setTimeout(()=>{
            setClosingWork(false)
            setWorkOpen(false);
            setFixMenu(false)
            navigate('/')
        }, 1000)
    }




    const location = useLocation()

    useEffect(()=> {

        if(location.pathname.startsWith("/work/") && !showOpen){
            setShowOpen(true)
            setWorkOpen(true)
        } else if (!location.pathname.startsWith("/work/") && workOpen) {
            //console.log("close")
            setWorkOpen(false)
        }


    },[location.pathname, showOpen, workOpen])



    return (

        <React.Fragment>

            <LoadingBar show={showLoadingBar} setShowLoadingBar={setShowLoadingBar}/>


            <Header menuOpen={showOpen || tellOpen} moving={moving} openMenu={openMenu} closeMenu={closeMenu}/>

            <Home openMenu={openMenu}/>


            <Menu
                menuOpen={showOpen || tellOpen}
                showOpen={showOpen}
                tellOpen={tellOpen}
                moving={moving}
                changing={changing}
                fixMenu={fixMenu}
                fade={fade}
                openWork={openWork}
                setShowLoadingBar={setShowLoadingBar}
            />

            <ShowFooter callback={()=>openMenu('show')}/>


            <Work
                workOpen={workOpen}
                closeWork={closeWork}
                closingWork={closingWork}
                workOpening={workOpening}
                setShowLoadingBar={setShowLoadingBar}
            />



        </React.Fragment>



    )

}

export default MainLayout;
